import React from 'react'
import './Menu.css'
import { Link } from "react-router-dom";
import { Link as LinkRoll } from "react-scroll";


import PHP from "../../assets/cognitive.webp";
import IOP from "../../assets/dialectal.webp";
import OP from "../../assets/interpersonal.webp";


const Menu = () => {
  return (

    <>
    <div className='menubg'>
    <div className='menu'>
        <div className='menu-content '>

        <div className="menu-header container">
          <h1>What We Can Offer</h1> <br />
            <p>
            At Sunset Rehab, we offer comprehensive addiction treatment tailored to individual recovery needs. Our Partial Hospitalization Program (PHP) provides a structured, intensive approach for those needing more support, while our Intensive Outpatient Program (IOP) offers flexibility for continued healing. Whether you're seeking drug and alcohol rehab, outpatient addiction treatment, or a holistic recovery program, our experienced team ensures the right level of care to help you or your loved one achieve lasting sobriety.
            </p>{" "}
          </div>



            <div className='menu-flex container'>

                <div className='menu-flexItem'>
                    <div className='menu-flexItem-img'>
                        <img src={PHP} alt="activities" />
                    </div>
                    <h1>Partial Hospitalization Program</h1>
                    <p>Our Partial Hospitalization Program (PHP) provides structured, evidence-based addiction treatment, offering intensive day programs for substance abuse recovery while allowing you to return home in the evenings.</p>
                    <div className='menu-flexItem-btn'>
                        <LinkRoll activeClass="active" to="top" spy={true} smooth={true} duration={500}>
                            <Link to="/php">
                                <button>LEARN MORE</button>
                            </Link>
                        </LinkRoll>
                    </div>
                </div>




                <div className='menu-flexItem'>
                    <div className='menu-flexItem-img'>
                        <img src={IOP} alt="activities" />
                    </div>
                    <h1>Intensive Outpatient Program</h1>
                    <p>Our Intensive Outpatient Program (IOP) offers structured addiction treatment, combining evidence-based therapy and flexible outpatient care to support long-term sobriety while maintaining daily responsibilities.</p>
                    <div className='menu-flexItem-btn'>
                        <LinkRoll activeClass="active" to="top" spy={true} smooth={true} duration={500}>
                            <Link to="/iop">
                                <button>LEARN MORE</button>
                            </Link>
                        </LinkRoll>
                    </div>
                </div>





                <div className='menu-flexItem'>
                    <div className='menu-flexItem-img'>
                        <img src={OP} alt="activities" />
                    </div>
                    <h1>Outpatient Program</h1>
                    <p>Our Outpatient Program offers flexible, evidence-based substance abuse treatment, allowing you to receive expert care while maintaining your daily routine.</p>
                    <div className='menu-flexItem-btn'>
                        <LinkRoll activeClass="active" to="top" spy={true} smooth={true} duration={500}>
                            <Link to="/op">
                                <button>LEARN MORE</button>
                            </Link>
                        </LinkRoll>
                    </div>
                </div>







            </div>

        </div>
    </div>
    </div>
    </>
    
  )
}

export default Menu