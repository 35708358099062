import React from 'react'
import Fade from 'react-reveal/Fade';
import './PhpSection.css'
import { AiFillCheckCircle } from 'react-icons/ai';

const PhpSection = () => {
  return (
    <>
<div className='php-wrap'>

<Fade right>
        <div className="php-wrap-left">
        <h1 className='header'>WHAT IS PARTIAL HOSPITALIZATION PROGRAM?</h1>

        </div>
    </Fade>


    <Fade left>
    <div className="php-wrap-right">
        <br/>
        <p>A Partial Hospitalization Program (PHP) is an intensive addiction treatment program designed for individuals who need structured support but do not require 24/7 residential care. It serves as a middle ground between inpatient rehab and outpatient treatment, providing a high level of care while allowing patients to return home in the evenings. 
        <br /> <br />
        At Sunset Rehab in Los Angeles, our PHP for addiction recovery includes:</p>
        <br />
        <ul>
            <li><p><span><AiFillCheckCircle /></span> Evidence-based therapy (CBT, DBT, trauma-informed care)</p></li>
            <li><p><span><AiFillCheckCircle /></span> Medical supervision and medication-assisted treatment (MAT)</p></li>
            <li><p><span><AiFillCheckCircle /></span> Individual and group counseling for emotional and behavioral support</p></li>
            <li><p><span><AiFillCheckCircle /></span> You’re motivated to engage in our treatment program.</p></li>
            <li><p><span><AiFillCheckCircle /></span> Holistic therapies like mindfulness, yoga, and nutrition counseling</p></li>
            <li><p><span><AiFillCheckCircle /></span> Relapse prevention planning to build long-term sobriety</p></li>
        </ul>
        <br />
        <p>Our PHP rehab program is ideal for individuals struggling with substance abuse, drug addiction, or co-occurring mental health disorders, offering a flexible yet structured environment for healing. If you or a loved one need intensive outpatient care in [Your Location], contact us today to begin your recovery journey.</p>
        
    </div>
    </Fade>

</div>
    



<div className='php-wrap2'>
    <div className='wrap2-content container'>
    <h1>WHAT TO EXPECT THROUGHOUT THE PROGRAM</h1><br /> <br />
<p>At Sunset Rehab, our Partial Hospitalization Program (PHP) and Intensive Outpatient Program (IOP) provide a structured yet flexible approach to addiction recovery. From the moment you begin treatment, our dedicated team ensures you receive the highest level of care tailored to your unique needs.  
<br /> <br />
Here’s what you can expect throughout the program:</p>
<br /><br />
        <ul>
            <li><p><span><AiFillCheckCircle /></span> Comprehensive Assessment & Personalized Treatment Plan. Each patient undergoes a thorough clinical evaluation to develop a customized addiction treatment plan, incorporating evidence-based therapy, holistic approaches, and medical support.</p></li>
            <li><p><span><AiFillCheckCircle /></span> Structured Therapy & Counseling. Our program includes individual counseling, group therapy, and family therapy led by licensed professionals. We utilize Cognitive Behavioral Therapy (CBT), Dialectical Behavior Therapy (DBT), and trauma-informed care to address the root causes of addiction.</p></li>
            <li><p><span><AiFillCheckCircle /></span> Medical Supervision & Medication-Assisted Treatment (MAT). For those struggling with substance use disorders, our PHP and IOP provide medical monitoring and MAT options to manage withdrawal symptoms and reduce cravings.</p></li>
            <li><p><span><AiFillCheckCircle /></span> You’re motivated to engage in our treatment program.</p></li>
            <li><p><span><AiFillCheckCircle /></span> Holistic and Wellness-Based Therapies. We believe in treating the whole person, not just the addiction. Patients participate in mindfulness practices, yoga, meditation, nutrition counseling, and fitness therapy to promote overall well-being.</p></li>
            <li><p><span><AiFillCheckCircle /></span> Relapse Prevention & Aftercare Planning. As patients progress, we focus on relapse prevention strategies and life skills training to support long-term recovery. Our team works closely with each individual to create a personalized aftercare plan, connecting them to outpatient services, 12-step programs, and sober living options.</p></li>
        </ul>
</div>
</div>

    </>
  )
}

export default PhpSection




