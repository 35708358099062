import React from 'react'
import './Hero.css'
import Fade from 'react-reveal/Fade';
import { Link } from 'react-router-dom'
import { Link as LinkRoll } from 'react-scroll'




const Hero = () => {
    return (
<>        
<div className='hero'>
    <div className="hero-container">

        <div className="content">
                <Fade bottom>
                <h1>Sunset Rehab</h1>
                <h2>Substance Abuse Addiction and Mental Health Treatment Facility</h2>
                <p>"Find your happiness and learn to put it first."</p>
                </Fade>
                <LinkRoll activeClass="active" to="top" spy={true} smooth={true} duration={500}>
            <Link to='/contact'>
                <div className='hero-btn '>
                    <button>CONTACT US</button>
                </div>
            </Link>
        </LinkRoll>
        </div>
    </div>
</div>
</>

    )
}

export default Hero