import React, { useRef, useState } from "react";
import { BsFillPinMapFill } from "react-icons/bs";
import { AiOutlinePhone, AiOutlineMail } from "react-icons/ai";
import emailjs from "emailjs-com";
import "./ContactForm.css";

const ContactForm = () => {
    const form = useRef();
    const [buttonText, setButtonText] = useState("Submit");

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm(
            "service_bdfl0ie", // Replace with your actual Service ID
            "template_0ap3xym",
            form.current,
            "CLcHWAKSemVMd1_sU" // Replace with your actual Public Key
        )
        .then((result) => {
            console.log("Email sent:", result.text);
            setButtonText("Message Sent");

            // Reset button text after 3 seconds
            setTimeout(() => {
                setButtonText("Submit");
            }, 3000);
        })
        .catch((error) => {
            console.error("Email sending error:", error.text);
        });

        e.target.reset();
    };

    return (
        <div className="contactform">
            <section className="container">
                <div className="footer_get_touch_inner">
                    <div className="get_form_inner">
                        <div className="get_form_inner_text">
                            <h3>Get In Touch</h3>
                            <p><i>We'll keep your contact information strictly confidential.</i></p><br/>
                        </div>
                        <form ref={form} onSubmit={sendEmail}>
                            <div className="inputs">
                                <input 
                                    type="text" 
                                    name="name" 
                                    placeholder="Your Name" 
                                    pattern="[A-Za-z\s]{2,50}" 
                                    title="Name should only contain letters and spaces (2-50 characters)." 
                                    required 
                                /><br/>
                                
                                <input 
                                    type="email" 
                                    name="email" 
                                    placeholder="Your Email" 
                                    required 
                                /><br/>

                                <input 
                                    type="tel" 
                                    name="phone" 
                                    placeholder="Your Phone" 
                                    pattern="^\d{10,15}$" 
                                    title="Phone number should be between 10 to 15 digits." 
                                    required 
                                /><br/>

                                <input 
                                    type="text" 
                                    name="subject" 
                                    placeholder="Subject" 
                                    pattern=".{3,100}" 
                                    title="Subject must be between 3 to 100 characters." 
                                    required 
                                /><br/>
                            </div>

                            <div>
                                <textarea 
                                    name="message" 
                                    placeholder="How can we help?" 
                                    cols="30" 
                                    rows="10" 
                                    minLength="10" 
                                    maxLength="1000" 
                                    required 
                                ></textarea>

                                <button type="submit">{buttonText}</button>
                            </div>
                        </form>
                    </div>
                </div>
            </section>

            <div className="contact-cards-container">
                <div className="get_form_inner2">
                    <h2>CALL US</h2>
                    <p><AiOutlinePhone /><a href="tel:3233304151"> +1 (323) 330-4151</a></p>
                </div>

                <div className="get_form_inner2">
                    <h2>EMAIL</h2>
                    <p><AiOutlineMail /> info@sunsetrehab.biz</p><br />
                </div>

                <div className="get_form_inner2">
                    <h2>LOCATION</h2>
                    <p><BsFillPinMapFill /> 7235 Santa Monica Blvd, West Hollywood, CA 90046</p>
                </div>
            </div>
        </div>
    );
};

export default ContactForm;
